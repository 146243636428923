<template>
  <div class="map">
    <v-tabs grow color="blue" v-model="selectedTab" show-arrows>
      <v-tab key="vineyard">{{ $t('Vineyard') }}</v-tab>
      <v-tab key="vegetables">{{ $t('Vegetables') }}</v-tab>
      <v-tab key="oldgreen">{{ $t('Old Greenhouse') }}</v-tab>
      <v-tab key="newgreen">{{ $t('New Greenhouse') }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab" touchless>
      <v-tab-item key="vineyard">
        <leaf-map :map-config="vineyard"></leaf-map>
      </v-tab-item>
      <v-tab-item key="vegetables">
        <leaf-map :map-config="vegetables"></leaf-map>
      </v-tab-item>
      <v-tab-item key="oldgreen">
        <leaf-map :map-config="oldgreen"></leaf-map>
      </v-tab-item>
      <v-tab-item key="newgreen">
        <leaf-map :map-config="newgreen"></leaf-map>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LeafMap from "@/components/LeafMap";
import VineYard from '@/assets/istandus.jpg';
import Vegetables from '@/assets/salatid.jpg';
import OldGreen from '@/assets/vana_kasvuhoone.jpg';
import NewGreen from '@/assets/uus_kasvuhoone.jpg';


export default {
  name: "ImageMap",
  components: { LeafMap },
  data: () => ({
    selectedTab: 'vineyard',
    vineyard: {
      category: 'vineyard',
      url: VineYard,
      bounds: [ [0, 0], [1000, 1400] ],
      maxBounds: [ [0, 0], [1000, 1400] ],
    },
    vegetables: {
      category: 'vegetables',
      url: Vegetables,
      bounds: [ [0, 0], [1000, 1400] ],
      maxBounds: [ [0, 0], [1000, 1400] ],
    },
    oldgreen: {
      category: 'oldgreen',
      url: OldGreen,
      bounds: [ [0, 0], [1200, 1400] ],
      maxBounds: [ [0, 0], [1200, 1400] ],
    },
    newgreen: {
      category: 'newgreen',
      url: NewGreen,
      bounds: [ [0, 0], [2000, 1400] ],
      maxBounds: [ [0, 0], [2000, 1400] ],
    },
  }),
  created() {},
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({})
  }
};
</script>
