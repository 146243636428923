<template>
  <v-row class="ma-2">

    <v-card class="col-11 ma-3" shaped raised style="display: flex; flex-direction: column">
      <v-list-item three-line style="display: block">
        <v-list-item-content>
          <div class="overline mb-4">{{ $t("Map") }}</div>
          <v-list-item-title class="headline mb-1">
            {{ $t("Map points") }}: {{ allPoints.length }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t("Most entered") }}: {{ user }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions>
        <v-row class="justify-center pa-3">
          <v-btn text :to="{ name: 'map' }">
            {{ $t("Look") }}
            <v-icon class="ma-2">mdi-plus-box-multiple</v-icon>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-card class="col-11 ma-3" shaped raised style="display: flex; flex-direction: column">
      <v-list-item three-line style="display: block">
        <v-list-item-content>
          <div class="overline mb-4">{{ $t("Posts") }}</div>
          <v-list-item-title class="headline mb-1">
            {{ $t("All posts") }}: {{ allPosts.length }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t("You have entered") }}: {{ allPosts.length }}
          </v-list-item-subtitle>
          <hr/>
          <ul>
            <li>{{ $t("Today is") }} {{ dateToLocaleEST() }}</li>
          </ul>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions>
        <v-row class="justify-center pa-3">
          <v-btn text :to="{ name: 'news' }">
            {{ $t("Read") }}
            <v-icon class="ma-2">mdi-book-open-variant</v-icon>
          </v-btn>
          <v-btn text :to="{ name: 'blog', params: { id: 'new' } }">
            {{ $t("Write") }}
            <v-icon class="ma-2">mdi-book-plus</v-icon>
          </v-btn>
        </v-row>
      </v-card-actions>
      <div>
        <v-card v-for="post in allPosts.slice(-3).reverse()" :key="post._id" shaped raised class="ma-3 grid-column">
          <post-entry :post="post" :actions="false" />
        </v-card>
      </div>
    </v-card>

  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import PostEntry from "@/components/PostEntry";

export default {
  name: "Dashboard",
  components: { PostEntry },
  data() {
    return {
      date: ""
    };
  },
  created() {
    this.$store.dispatch("post/getAllPosts");
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      allPosts: "post/allPosts",
      allPoints: "point/allPoints"
    })
  },
  methods: {
    dateToLocaleEST() {
      return new Date().toLocaleDateString("ET-ee", {
        month: "long",
        day: "numeric",
        year: "numeric"
      });
    }
  }
};
</script>
