<template>
  <v-app-bar app short absolute width="100%">
    <v-btn text @click="routeAbout">
      <v-toolbar-title class="headline text-uppercase">
        {{ $t("app-name") }}
      </v-toolbar-title>
    </v-btn>
    <locale-changer></locale-changer>
    <div class="flex-grow-1"></div>
    <div class="app-bar" v-show="$vuetify.breakpoint.smAndUp">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-chip v-on="on" class="mx-2" rounded>
            <v-avatar left size="20">
              <v-img class="elevation-6" alt="avatar" :src="user_avatar"/>
            </v-avatar>
            {{ user }}
          </v-chip>
        </template>
        <span>{{ $t("User") }}: {{ user }}</span>
      </v-tooltip>
      <v-tooltip bottom color="green">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon color="green" :disabled="refreshDisabled" @click="refreshDatabase">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Load data from database") }}</span>
      </v-tooltip>
      <v-divider class="mr-3" vertical inset></v-divider>
      <v-btn-toggle v-model="selectedPage" v-show="$vuetify.breakpoint.mdAndUp" dark dense>
        <v-btn value="1" text outlined :to="{ name: 'news' }">{{ $t("News") }}</v-btn>
        <v-btn value="2" text outlined :to="{ name: 'blog', params: { id: 'new' } }">{{ $t("Blog") }}</v-btn>
        <v-btn value="3" text outlined :to="{ name: 'map' }">{{ $t("Map") }}</v-btn>
        <v-btn value="7" text outlined :to="{ name: 'tree', params: { id: 'view' } }">{{ $t("Family Tree") }}</v-btn>
        <v-btn value="6" text outlined :to="{ name: 'database' }">{{ $t("Database") }}</v-btn>
      </v-btn-toggle>
      <v-divider class="mr-3" vertical inset></v-divider>
      <v-btn text @click="logout">
        {{ $t("Log out") }}<v-icon class="ml-1 mr-3">mdi-logout</v-icon>
      </v-btn>
    </div>
    <v-app-bar-nav-icon v-show="$vuetify.breakpoint.smAndDown" @click="drawer = true"></v-app-bar-nav-icon>
    <v-navigation-drawer v-model="drawer" absolute temporary right style="height: 100vh">
      <v-system-bar></v-system-bar>
      <v-list  nav dense>
        <v-list-item>
          <v-list-item-avatar>
            <v-img src=""></v-img>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="title">{{ this.user }}</v-list-item-title>
            <v-list-item-subtitle>{{ this.user_email }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action icon color="green" @click="refreshDatabase">
            <v-icon>mdi-refresh</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="text--accent-4">
          <v-list-item :to="{ name: 'news' }">
            <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
            <v-list-item-title>{{ $t("News") }}</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'blog', params: { id: 'new' } }">
            <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
            <v-list-item-title>{{ $t("Blog") }}</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'map' }">
            <v-list-item-icon><v-icon>mdi-map</v-icon></v-list-item-icon>
            <v-list-item-title>{{ $t("Map") }}</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'tree', params: { id: 'view' } }">
            <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
            <v-list-item-title>{{ $t("Family Tree") }}</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'database' }">
            <v-list-item-icon><v-icon>mdi-database</v-icon></v-list-item-icon>
            <v-list-item-title>{{ $t("Database") }}</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout">
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
            <v-list-item-title>{{ $t("Log out") }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-app-bar>
</template>

<script>
import {mapGetters} from "vuex";
import LocaleChanger from "@/components/LocaleChanger";

export default {
  name: "AppBar",
  components: {
    LocaleChanger
  },
  data: () => ({
    refreshDisabled: false,
    drawer: null,
    group: null,
    selectedPage: null
  }),
  computed: {
    ...mapGetters({
      user: "user/getUser",
      user_email: "user/getUserEmail",
      user_avatar: "user/getAvatar",
    }),
  },
  created() {
    // FIXME: hack for dev env hot reload
    this.$store.dispatch("user/refreshToken");
  },
  methods: {
    logout() {
      this.$store.dispatch("user/logoutUser");
      this.$router.push({ name: "login" });
    },
    routeAbout() {
      this.$router.push({ name: "home" });
    },
    refreshDatabase() {
      this.$store.dispatch("post/getAllPosts");
      this.$store.dispatch("vine/getAllVines");
      this.$store.dispatch("sort/getAllSorts");
      this.$store.dispatch("tag/getAllTags");
      this.$store.dispatch("category/getAllCategories");
      this.$store.dispatch("point/getAllPoints");
      this.$store.dispatch("person/getAllPersons");

      this.$store.dispatch("message/setMessage", {
        text: this.$t("Reloaded"),
        type: "success"
      });
    }
  },
  watch: {
    '$route' (newVal, oldVal) {
      // this will be called any time the route changes
      this.refreshDisabled = newVal.name === 'tree';
    }
  },
};
</script>

<style scoped>
.app-bar {
  white-space: nowrap;
}
</style>
