<template>
  <v-container class="locale-changer mt-3">
    <v-row class="d-flex">
      <v-btn @click="switchModel()" :title="$t('Change language')" icon>
        <v-icon>mdi-translate</v-icon>
      </v-btn>
      <v-switch class="ml-4 mt-3" :title="$t('Change theme')" v-model="$vuetify.theme.dark"/>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LocaleChanger',
  data: () => ({}),
  methods: {
    switchModel() {
      this.$i18n.locale = this.$i18n.locale === 'en' ? 'et' : 'en';
    }
  },
}
</script>