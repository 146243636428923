<template>
  <v-container>
    <v-row justify="center">
      <UserInfo :submitform="register" />
    </v-row>
  </v-container>
</template>

<script>
import UserInfo from "@/components/UserInfo";

export default {
  name: "Register",
  components: { UserInfo },
  methods: {
    async register(userInfo) {
      let response = await this.$store
        .dispatch("user/registerUser", userInfo)
        .then(response => {
          if (response.status !== 200) {
            this.$store.dispatch("message/setMessage", {
              text: this.$t(response.message),
              type: "warning",
              timeout: 3000
            });
          } else {
            this.$store.dispatch("message/setMessage", {
              text: this.$t(response.message),
              type: "success"
            });
            this.$router.push({ name: "login" });
          }
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("message/setMessage", {
            text: this.$t(err.message),
            type: "error",
            timeout: 5000
          });
        });
    }
  }
};
</script>
