<template>
  <v-container :class="'database-vine' ">
    <br>
    <v-form v-model="valid" ref="vine">

      <p>{{ $t("Enter vine") }}</p>

      <v-row>
<!--        <v-text-field class="mx-1" cols="3" dense outlined v-model="vine.sort" :rules="rules" :label="$t('Sort')"/>-->
        <v-select class="mx-1" cols="3" :items="allSortNames" dense outlined v-model="vine.sort" :rules="rules" :label="$t('Sort')" />
        <v-text-field class="mx-1" cols="3" dense outlined v-model="vine.name" :rules="rules" :label="$t('Name')"/>
      </v-row>

      <v-row>
        <v-text-field class="mx-1" cols="3" dense outlined v-model="vine.desc" :label="$t('Description')"/>
      </v-row>

      <form-buttons :valid="valid" @save="saveModel" @reset="resetModel"/>

    </v-form>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FormButtons from "@/components/FormButtons";

export default {
  name: "VineForm",
  components: { FormButtons },
  props: {
    model: Object
  },
  data: () => ({
    valid: false,
    rules: [v => !!v || 'Kohustuslik'], // TODO translate
    vine: {
      sort: null,
      name: '',
      desc: '',
    }
  }),
  computed: {
    ...mapGetters({
      user: "user/getUser",
      allSorts: "sort/allSorts"
    }),
    allSortNames() {
      return this.allSorts.map(s => s.name)
    }
  },
  created() {
    this.$store.dispatch("vine/getAllVines");
  },
  methods: {
    ...mapActions({
      newVine: "vine/addNewVine",
      setMessage: "message/setMessage"
    }),
    async saveVine(model) {
      model = {
        ...model,
        user: this.user,
      };

      let res = await this.newVine(model);

      if (res.status === 201) {
        this.setMessage({
          text: `${this.$t(res.data.message)} (${res.data.data.sort}: ${res.data.data.name})`,
          type: "success"
        });
      }
    },
    saveModel() {
      this.validateModel();
      if (this.valid) {
        this.saveVine(this.vine)
      }
      this.resetModel();
    },
    validateModel() {
      this.valid = this.$refs.vine.validate();
    },
    resetModel() {
      this.$refs.vine.reset();
    },
  }
}
</script>