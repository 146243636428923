<template>

  <v-container class="leafmap">
    <v-row>
      <!-- LEAFLET DATA TABLE -->
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="allMarkers"
            item-key="_id"
            class="elevation-2"
            :search="search"
            :rowsPerPage="10"
            :items-per-page="5"
            :footer-props="footerProps"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>PUNKTID</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field v-model="search" :label="$t('Search')" class="mt-6"/>
            </v-toolbar>
          </template>

          <template v-slot:item.name="props">
            <div class="mr-n2">
              <v-edit-dialog :return-value.sync="props.item.name" @save="update(props.item)">
                {{ props.item.name }}
                <template v-slot:input>
                  <v-text-field v-model="props.item.name" label="Edit" single-line counter
                                :rules="[v => v.length <= 20 || $t('Too long')]"
                  />
                </template>
              </v-edit-dialog>
            </div>
          </template>

          <template v-slot:header.tooltip="{ header }">
            <span class="ml-n2">{{ header.text }}</span>
          </template>
          <template v-slot:item.tooltip="props">
            <div class="ma-n2">
              <v-edit-dialog :return-value.sync="props.item.tooltip" @save="update(props.item)">
                {{ props.item.tooltip }}
                <template v-slot:input>
                  <v-text-field v-model="props.item.tooltip" :label="$t('Edit')" single-line counter
                                :rules="[v => v.length <= 100 || $t('Too long')]"
                  />
                </template>
              </v-edit-dialog>
            </div>
          </template>
          <template v-slot:header.category="{ header }">
            <span class="ml-n2">{{ header.text }}</span>
          </template>
          <template v-slot:item.category="props">
            <div class="ma-n2">
              <v-select v-model="props.item.category" :items="mapCategories" style="top:5px"
                        :return-value.sync="props.item.category" @change="update(props.item)"
              >
              </v-select>
            </div>
          </template>

          <template v-slot:header.draggable="{ header }">
            <v-icon :title="header.text" class="ma-n2">mdi-map-marker</v-icon>
          </template>
          <template v-slot:item.draggable="{ item }">
            <v-simple-checkbox v-if="item.type === 'marker'" v-model="item.draggable" :ripple="false" class="ma-n2"/>
          </template>

          <template v-slot:header.visible="{ header }">
            <v-icon :title="header.text" class="ma-n2">mdi-eye-outline</v-icon>
          </template>
          <template v-slot:item.visible="{ item }">
            <v-simple-checkbox v-model="item.visible" :ripple="false" class="ma-n2"/>
          </template>

          <template v-slot:header.color="{ header }">
            <v-icon :title="header.text" class="ma-n2">mdi-palette</v-icon>
          </template>
          <template v-slot:item.color="props">
            <v-swatches v-model="props.item.color" shapes="circles"
                        :swatches='swatches' :swatch-size="25" :row-length="8"
                        popover-x="left" popover-y="top" show-border
                        @input="update(props.item)"
                        :trigger-style="swatchStyle"
                        :wrapper-style="{}"
            ></v-swatches>
          </template>
          <template v-slot:item.position="{ item }">
            <v-btn :disabled="!isMoved(item._id)" color="success" x-small @click="update(item)">Uuenda asukoht</v-btn>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon color="error" @dblclick="removePoint(item._id)">mdi-delete</v-icon>
          </template>
          <template v-slot:footer.page-text="props">
            {{ props.pageStart }}-{{ props.pageStop }} ({{ props.itemsLength }})
          </template>
        </v-data-table>
      </v-col>
      <!-- LEAFLET DATA TABLE end -->

      <!-- LEAFLET MAP -->
      <v-col cols="12">
        <l-map id="imageMap" ref="map" :max-zoom="2" :min-zoom="-2" :crs="crs"
               :max-bounds="maxBounds" style="height: 80vh; width: 100%; z-index: 0;"
        >
          <l-image-overlay :url="imageLayer.url" :bounds="imageLayer.bounds" :opacity="dynamicOpacity" />
          <l-polygon v-for="poly in allPolys" :key="poly._id"
                     :lat-lngs="poly.position"
                     :color="poly.color"
                     :visible="poly.visible"
                     tooltip="true">
            <l-popup :content="poly.text"></l-popup>
            <l-tooltip :content="poly.name"/>
          </l-polygon>
          <l-marker v-for="point in allPoints"
                    :key="point._id"
                    :lat-lng.sync="point.position"
                    :visible="point.visible"
                    :draggable="point.draggable"
                    @dragstart="positionChange(point._id)"
                    @dragend="updatePosition(point)"
                    :icon="getIcon(point)" tooltip="true"
                    @update:lat-lng="updateLatLng(point)"
          >
            <l-popup :content="point.text"></l-popup>
            <l-tooltip :content="point.name"/>
          </l-marker>
        </l-map>
        <v-row>
          <v-slider class="mt-4" v-model="opacity" :min="1" :max="10" ticks></v-slider>
        </v-row>
      </v-col>
      <!-- LEAFLET MAP end -->
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {LImageOverlay, LMap, LMarker, LPolygon, LPopup, LTooltip} from "vue2-leaflet";
import {Control, CRS, divIcon, latLngBounds} from "leaflet";
import LDraw from "leaflet-draw";
import VSwatches from "vue-swatches";

export default {
  name: "LeafMap",
  props: {
    mapConfig: Object
  },
  components: {LMap, LImageOverlay, LMarker, LPopup, LTooltip, VSwatches, LDraw, LPolygon},
  data() {
    return {
      crs: CRS.Simple,
      opacity: 100,
      imageLayer: {
        url: this.mapConfig.url,
        bounds: latLngBounds(this.mapConfig.bounds)
      },
      renewedPositions: new Set(),
      search: "",
      headers: [
        {text: this.$t("Code"), value: "code", filterable: true, sortable: false, width: "3%"},
        {text: this.$t("Name"), value: "name", filterable: true, width: "30%"},
        {text: this.$t("Tooltip"), value: "tooltip", filterable: true, width: "32%"},
        {text: this.$t("Category"), value: "category", sortable: true, width: "16%"},
        {text: this.$t("Draggable"), value: "draggable", sortable: false, width: "3%"},
        {text: this.$t("Visible"), value: "visible", sortable: false, width: "3%"},
        {text: this.$t("Color"), value: "color", sortable: false, width: "3%"},
        {text: "", value: "position", sortable: false, width: "5%", align: "right"},
        {text: "", value: "actions", sortable: false, width: "3%", align: "right"},
      ],
      maxBounds: latLngBounds(this.mapConfig.maxBounds),
      footerProps: {
        itemsPerPageText: "",
        itemsPerPageAllText: this.$t("All"),
        pageText: `{page} ${this.$t("And")} {pages}`
      },
      swatches: [
        "#CC0001", "#009900", "#0066CB",
        "#ece918", "#ff4d00", "#da29ff",
        "#000000", "#dbd7d7"
      ],
      swatchStyle: {marginLeft: "-5px", width: "20px", height: "20px"},
      mapCategories: [
        {text: "Istandus", value: "vineyard"},
        {text: "Köögiviljad", value: "vegetables"},
        {text: "Vana kasvuhoone", value: "oldgreen"},
        {text: "Uus kasvuhoone", value: "newgreen"},
      ]
    };
  },
  created() {
    this.$store.dispatch("point/getAllPoints");
    this.$store.dispatch("category/getAllCategories");
  },
  mounted() {
    this.$nextTick(() => {
      const map = this.$refs.map.mapObject;
      map.addControl(new Control.Fullscreen());
      const drawControl = new Control.Draw({
        position: "topleft",
        draw: {
          marker: {
            icon: this.getIcon({ color:'#dbd7d7' }),
          },
          polygon: true,
          polyline: false,
          rectangle: false,
          circle: false,
          circlemarker: false
        },
        edit: false
      });
      map.addControl(drawControl);
      const editableLayers = new window.L.FeatureGroup().addTo(map);
      map.on(window.L.Draw.Event.CREATED, (e) => {
        const type = e.layerType;
        const layer = e.layer;
        // afterActions
        // editableLayers.addLayer(layer); // original, to add local layer // this case loads from db
        this.addMarker(type === 'marker' ? layer._latlng : layer._latlngs, type);
      });
    });
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      allStorePoints: "point/allPoints",
      allCategories: "category/allCategories",
    }),
    dynamicOpacity() {
      return this.opacity/10;
    },
    popUpInfo(point) {
      return `<p><b>info</b> ${point.name}</p>`
    },
    allMarkers() {
      return this.allStorePoints.filter(p => p.category === this.mapConfig.category);
    },
    allPoints() {
      return this.allMarkers.filter(p => p.type === 'marker');
    },
    allPolys() {
      return this.allMarkers.filter(p => p.type === 'polygon');
    }
  },
  methods: {
    ...mapActions({
      setMessage: "message/setMessage",
      addNewPoint: "point/addNewPoint",
      updatePoint: "point/updateOnePoint",
      deletePoint: "point/deletePoint",
    }),
    async addMarker(latLng, shape) {
      let newMarker = {
        name: shape === 'marker' ? "uus marker" : 'uus ala',
        category: this.mapConfig.category,
        type: shape,
        position: latLng,
        draggable: true,
        visible: true,
        color: "#dbd7d7",
        tooltip: shape === 'marker' ? "markeri lisainfo" : 'ala lisainfo',
        user: this.user,
      };
      let res = await this.addNewPoint(newMarker);
      if (res.status === 201) {
        this.setMessage({
          text: `${this.$t(res.data.message)} (${res.data.data.code}: ${res.data.data.name})`,
          type: "success"
        });
      }
    },
    getIcon(item) {
      let svgContent = `<svg height="50" width="50" viewBox="0 0 100 100" fill="${item.color}" ><circle cx="20" cy="20" r="20" stroke="black" stroke-width="1" /></svg>`;
      return divIcon({
        html: svgContent,
        className: "map-marker-icon",
        iconSize: [40, 40],
        iconAnchor: [9, 9],
      });
    },
    async removePoint(id) {
      let res = await this.deletePoint(id);
      if (res.status === 200) {
        this.setMessage({
          text: `${this.$t(res.data.message)} (${res.data.data.id})`,
          type: "success"
        });
      }
    },
    positionChange(_id) {
      this.renewedPositions.add(_id);
    },
    updatePosition(point) {
      this.update(point);
    },
    isMoved(id) {
      return this.renewedPositions.has(id)
    },
    async update(point) {
      let res = await this.updatePoint(point);
      if (res.status === 200) {
        this.setMessage({
          text: `${this.$t(res.data.message)} (${res.data.data.code}: ${res.data.data.name})`,
          type: "success"
        });
        this.renewedPositions.delete(point._id);
      } else {
        this.setMessage({
          text: `${this.$t(res.data.message)} (${res.data.error})`,
          type: "error"
        });
      }
    },
    updateLatLng(point) {

    }
  },
}
</script>

<style scoped>
.text-truncate {
  overflow: hidden;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>