import axios from "axios";

export default {
  namespaced: true,
  state: {
    vines: []
  },
  getters: {
    allVines: state => state.vines,
    oneVineById: state => id => {
      return state.vines.find(vine => vine._id === id);
    }
  },
  actions: {
    async getAllVines({ commit }) {
      let response = await axios.get("api/vine");

      commit("SET_VINES",
        response.data.map(vine => ({
          ...vine,
          createdAt: new Date(vine.createdAt).toISOString(),
          heading: vine.name + ' (' + vine.sort + ')'
        }))
      );
    },

    async addNewVine({ commit, dispatch, state }, newVine) {
      newVine.createdAt = Date.now();
      newVine.createdBy = newVine.user;
      let maxCode = state.vines.reduce((max,p) => Math.max(max, p.code), state.vines[0].code);
      newVine.code = maxCode + 1;

      let response = await axios.post("api/vine", newVine);

      commit("NEW_VINE", newVine);
      dispatch("vine/getAllVines", null, { root: true });
      return response;
    },

    async updateOneVine({ dispatch }, newVine) {
      newVine.updatedAt = Date.now();
      newVine.updatedBy = newVine.user;

      let response = await axios.put(`api/vine/${newVine._id}`, newVine);

      dispatch("vine/getAllVines", null, { root: true });
      return response;
    },

    async deleteVine({ commit }, id) {
      let response = await axios.delete(`api/vine/${id}`);

      commit("DELETE_VINE", id);
      return response;
    },

    emptyStore({ commit }) {
      commit("EMPTY_STORE");
    }
  },
  mutations: {
    SET_VINES: (state, vines) => (state.vines = vines),
    NEW_VINE: (state, newVine) => state.vines.push(newVine),
    DELETE_VINE: (state, id) =>
      (state.vines = state.vines.filter(rec => rec._id !== id)),
    EMPTY_STORE: state => {
      state.vines = [];
    }
  }
};
