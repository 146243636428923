<template>
  <v-col class="col-xs-12 col-sm-8 col-md-6 col-lg-4 text-xs-center display-1 mx-3">
    <v-row justify="center">
      <a href="https://www.veinipiiga.ee"><v-img max-height="300" max-width="300" src="../assets/logo.png"/></a>
    </v-row>
    <v-card light class="pa-2">
      <v-card-text class="subheading">
        <v-row class="mx-2">
          <p class="subtitle-1">{{ title }}</p>
          <v-spacer class="ml-auto" />
          <v-btn text color="red darken-4" :to="{ name: 'about' }">
            <p class="title pt-4">{{ $t("app-name") }}</p>
          </v-btn>
        </v-row>
        <v-form v-model="valid">
          <v-text-field v-if="!islogin" v-model="user.key" :label="$t('Secret key')" prepend-icon="mdi-key-variant" :autofocus="!islogin" :rules="[required('võti')]" />
          <v-text-field v-model="user.email" :label="$t('E-mail')" prepend-icon="mdi-email" :autofocus="islogin" :rules="[required('e-mail'), emailFormat()]" />
          <v-text-field v-model="user.password" :label="$t('Password')" prepend-icon="mdi-lock" counter :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword"
                        :rules="[required('parool'), minLength('Parool', 6)]" @keyup.enter="!valid || submitform(user)" />
          <v-row class="align-center ml-2 mt-2">
            <v-btn color="primary" @submit.prevent @click="submitform(user)" :disabled="!valid" >
              {{ title }}
            </v-btn>
            <v-btn light class="ma-3" :to="changeModule" small>
              {{ islogin ? $t("Register") : $t("Login") }}
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import validations from "@/utils/validations";

export default {
  name: "UserInfo",
  props: {
    submitform: Function,
    islogin: Boolean
  },
  data() {
    return {
      valid: false,
      showPassword: false,
      user: {
        key: "",
        email: "",
        password: ""
      },
      ...validations
    };
  },
  computed: {
    title() {
      return this.islogin ? this.$t("Login") : this.$t("Register");
    },
    changeModule() {
      return this.islogin ? { name: "register" } : { name: "login" };
    }
  },
  methods: {
    routeAbout() {
      this.$router.push({ name: "about" });
    }
  }
};
</script>
