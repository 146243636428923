<template>
  <v-container class="vineyard">
    <h1>{{ $t("Vineyard") }}</h1>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Vineyard",
  data: () => ({}),
  created() {},
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({})
  }
};
</script>
