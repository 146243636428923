var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"leafmap"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.allMarkers,"item-key":"_id","search":_vm.search,"rowsPerPage":10,"items-per-page":5,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("PUNKTID")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{staticClass:"mt-6",attrs:{"label":_vm.$t('Search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.name",fn:function(props){return [_c('div',{staticClass:"mr-n2"},[_c('v-edit-dialog',{attrs:{"return-value":props.item.name},on:{"update:returnValue":function($event){return _vm.$set(props.item, "name", $event)},"update:return-value":function($event){return _vm.$set(props.item, "name", $event)},"save":function($event){return _vm.update(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","counter":"","rules":[function (v) { return v.length <= 20 || _vm.$t('Too long'); }]},model:{value:(props.item.name),callback:function ($$v) {_vm.$set(props.item, "name", $$v)},expression:"props.item.name"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.name)+" ")])],1)]}},{key:"header.tooltip",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"ml-n2"},[_vm._v(_vm._s(header.text))])]}},{key:"item.tooltip",fn:function(props){return [_c('div',{staticClass:"ma-n2"},[_c('v-edit-dialog',{attrs:{"return-value":props.item.tooltip},on:{"update:returnValue":function($event){return _vm.$set(props.item, "tooltip", $event)},"update:return-value":function($event){return _vm.$set(props.item, "tooltip", $event)},"save":function($event){return _vm.update(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":_vm.$t('Edit'),"single-line":"","counter":"","rules":[function (v) { return v.length <= 100 || _vm.$t('Too long'); }]},model:{value:(props.item.tooltip),callback:function ($$v) {_vm.$set(props.item, "tooltip", $$v)},expression:"props.item.tooltip"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.tooltip)+" ")])],1)]}},{key:"header.category",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"ml-n2"},[_vm._v(_vm._s(header.text))])]}},{key:"item.category",fn:function(props){return [_c('div',{staticClass:"ma-n2"},[_c('v-select',{staticStyle:{"top":"5px"},attrs:{"items":_vm.mapCategories,"return-value":props.item.category},on:{"update:returnValue":function($event){return _vm.$set(props.item, "category", $event)},"update:return-value":function($event){return _vm.$set(props.item, "category", $event)},"change":function($event){return _vm.update(props.item)}},model:{value:(props.item.category),callback:function ($$v) {_vm.$set(props.item, "category", $$v)},expression:"props.item.category"}})],1)]}},{key:"header.draggable",fn:function(ref){
var header = ref.header;
return [_c('v-icon',{staticClass:"ma-n2",attrs:{"title":header.text}},[_vm._v("mdi-map-marker")])]}},{key:"item.draggable",fn:function(ref){
var item = ref.item;
return [(item.type === 'marker')?_c('v-simple-checkbox',{staticClass:"ma-n2",attrs:{"ripple":false},model:{value:(item.draggable),callback:function ($$v) {_vm.$set(item, "draggable", $$v)},expression:"item.draggable"}}):_vm._e()]}},{key:"header.visible",fn:function(ref){
var header = ref.header;
return [_c('v-icon',{staticClass:"ma-n2",attrs:{"title":header.text}},[_vm._v("mdi-eye-outline")])]}},{key:"item.visible",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{staticClass:"ma-n2",attrs:{"ripple":false},model:{value:(item.visible),callback:function ($$v) {_vm.$set(item, "visible", $$v)},expression:"item.visible"}})]}},{key:"header.color",fn:function(ref){
var header = ref.header;
return [_c('v-icon',{staticClass:"ma-n2",attrs:{"title":header.text}},[_vm._v("mdi-palette")])]}},{key:"item.color",fn:function(props){return [_c('v-swatches',{attrs:{"shapes":"circles","swatches":_vm.swatches,"swatch-size":25,"row-length":8,"popover-x":"left","popover-y":"top","show-border":"","trigger-style":_vm.swatchStyle,"wrapper-style":{}},on:{"input":function($event){return _vm.update(props.item)}},model:{value:(props.item.color),callback:function ($$v) {_vm.$set(props.item, "color", $$v)},expression:"props.item.color"}})]}},{key:"item.position",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!_vm.isMoved(item._id),"color":"success","x-small":""},on:{"click":function($event){return _vm.update(item)}}},[_vm._v("Uuenda asukoht")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"error"},on:{"dblclick":function($event){return _vm.removePoint(item._id)}}},[_vm._v("mdi-delete")])]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" ("+_vm._s(props.itemsLength)+") ")]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('l-map',{ref:"map",staticStyle:{"height":"80vh","width":"100%","z-index":"0"},attrs:{"id":"imageMap","max-zoom":2,"min-zoom":-2,"crs":_vm.crs,"max-bounds":_vm.maxBounds}},[_c('l-image-overlay',{attrs:{"url":_vm.imageLayer.url,"bounds":_vm.imageLayer.bounds,"opacity":_vm.dynamicOpacity}}),_vm._l((_vm.allPolys),function(poly){return _c('l-polygon',{key:poly._id,attrs:{"lat-lngs":poly.position,"color":poly.color,"visible":poly.visible,"tooltip":"true"}},[_c('l-popup',{attrs:{"content":poly.text}}),_c('l-tooltip',{attrs:{"content":poly.name}})],1)}),_vm._l((_vm.allPoints),function(point){return _c('l-marker',{key:point._id,attrs:{"lat-lng":point.position,"visible":point.visible,"draggable":point.draggable,"icon":_vm.getIcon(point),"tooltip":"true"},on:{"update:latLng":function($event){return _vm.$set(point, "position", $event)},"update:lat-lng":[function($event){return _vm.$set(point, "position", $event)},function($event){return _vm.updateLatLng(point)}],"dragstart":function($event){return _vm.positionChange(point._id)},"dragend":function($event){return _vm.updatePosition(point)}}},[_c('l-popup',{attrs:{"content":point.text}}),_c('l-tooltip',{attrs:{"content":point.name}})],1)})],2),_c('v-row',[_c('v-slider',{staticClass:"mt-4",attrs:{"min":1,"max":10,"ticks":""},model:{value:(_vm.opacity),callback:function ($$v) {_vm.opacity=$$v},expression:"opacity"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }