import axios from "axios";

export default {
  namespaced: true,
  state: {
    persons: []
  },
  getters: {
    allPersons: state => state.persons,
    allMalePersons: state => state.persons.filter(p => p.sex === 'M'),
    allFemalePersons: state => state.persons.filter(p => p.sex === 'F'),
    onePersonById: state => id => {
      return state.persons.find(person => person._id === id);
    }
  },
  actions: {
    async getAllPersons({ commit }) {
      let response = await axios.get("api/person");

      commit("SET_PERSONS",
        response.data.map(person => ({
          ...person,
          createdAt: new Date(person.createdAt).toISOString(),
          text: person.name + ' (' + (person.birth ? person.birth : '?') + '-' + (person.death ? person.death : '?') + ')',
          value: person.code,
          heading: person.name + ' (' + person.sex + ')',
        }))
      );
    },

    async reloadPersonFromDb({ commit, state }, id) {
      let response = await axios.get(`api/person/${id}`);

      let model = response.data;
      let newModel = model;

      for (let key in model) {
        if (model.hasOwnProperty(key)) {
          switch (key) {
            case 'updatedAt':
              newModel[key] = new Date(model.updatedAt).toISOString();
              break;
            case 'createdAt':
              newModel[key] = new Date(model.createdAt).toISOString();
              break;
          }
        }
      }
      newModel['text'] = model.name + ' (' + (model.birth ? model.birth : '?') + '-' + (model.death ? model.death : '?') + ')';
      newModel['value'] = model.code;
      newModel['heading'] = model.name + ' (' + model.sex + ')';

      state.persons.filter(p => p._id !== model._id);
      state.persons.push(newModel);
      return newModel;
    },

    async addNewPerson({ commit, dispatch, state }, newPerson) {
      newPerson.createdAt = Date.now();
      newPerson.createdBy = newPerson.user;
      let maxCode = state.persons.reduce((max,p) => Math.max(max, p.code), state.persons[0].code);
      newPerson.code = maxCode + 1;

      let response = await axios.post("api/person", newPerson);

      commit("NEW_PERSON", newPerson);
      dispatch("person/getAllPersons", null, { root: true });
      return response;
    },

    async updateOnePerson({ dispatch, state }, newPerson) {
      newPerson.updatedAt = Date.now();
      newPerson.updatedBy = newPerson.user;

      let response = await axios.put(`api/person/${newPerson._id}`, newPerson);

      state.persons.filter(p => p._id !== newPerson._id);
      state.persons.push(newPerson);

      dispatch("person/getAllPersons", null, { root: true });
      return response;
    },

    async deletePerson({ commit }, id) {
      let response = await axios.delete(`api/person/${id}`);

      commit("DELETE_PERSON", id);
      return response;
    },

    emptyStore({ commit }) {
      commit("EMPTY_STORE");
    }
  },
  mutations: {
    SET_PERSONS: (state, persons) => (state.persons = persons),
    NEW_PERSON: (state, newPerson) => state.persons.push(newPerson),
    DELETE_PERSON: (state, id) =>
      (state.persons = state.persons.filter(person => person._id !== id)),
    EMPTY_STORE: state => {
      state.persons = [];
    }
  }
};
