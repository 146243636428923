import axios from "axios";

export default {
  namespaced: true,
  state: {
    points: []
  },
  getters: {
    allPoints: state => state.points,
    onePointById: state => id => {
      return state.points.find(point => point._id === id);
    }
  },
  actions: {
    async getAllPoints({ commit }) {
      let response = await axios.get("api/point");

      commit("SET_POINTS",
        response.data.map(point => ({
          ...point,
          createdAt: new Date(point.createdAt).toISOString(),
          position: point.type === 'marker' ? point.position[0] : point.position,
          text: `<p>${point.name.toUpperCase()}</p><p>${point.tooltip}</p>`
        }))
      );
    },

    async addNewPoint({ commit, dispatch, state }, newPoint) {
      newPoint.createdAt = Date.now();
      newPoint.createdBy = newPoint.user;
      let maxCode = state.points.reduce((max,p) => Math.max(max, p.code), state.points[0].code);
      newPoint.code = maxCode + 1;
      newPoint.position = newPoint.type === 'marker' ? [newPoint.position] : newPoint.position;

      let response = await axios.post("api/point", newPoint);

      commit("NEW_POINT", newPoint);
      dispatch("point/getAllPoints", null, { root: true });
      return response;
    },

    async updateOnePoint({ commit, dispatch }, updatedPoint) {
      updatedPoint.updatedAt = Date.now();
      updatedPoint.updatedBy = updatedPoint.user;
      updatedPoint.position = updatedPoint.type === 'marker' ? [updatedPoint.position] : updatedPoint.position;

      let response = await axios.put(`api/point/${updatedPoint._id}`, updatedPoint);

      commit("UPDATE_POINT", updatedPoint);
      dispatch("point/getAllPoints", null, { root: true });
      return response;
    },

    async deletePoint({ commit }, id) {
      let response = await axios.delete(`api/point/${id}`);

      commit("DELETE_POINT", id);
      return response;
    },

    emptyStore({ commit }) {
      commit("EMPTY_STORE");
    }
  },

  mutations: {
    SET_POINTS: (state, points) => (state.points = points),
    NEW_POINT: (state, newPoint) => state.points.push(newPoint),
    UPDATE_POINT: (state, updatedPoint) => state.points.map((point) => {
      if(point._id === updatedPoint._id) {
        if (updatedPoint.type === 'marker') {
          point.position = updatedPoint.position;
        }
      }
    }),
    DELETE_POINT: (state, id) =>
        (state.points = state.points.filter(point => point._id !== id)),
    EMPTY_STORE: state => {
      state.points = [];
    }
  }
};
