<template>
  <v-app>
    <AppBar v-if="isLoggedIn" width="100%" />

<!--    <v-progress-linear v-show="loading === false" absolute :indeterminate="loading" height="4"/>-->

    <v-main class="ma-2">
      <router-view />
    </v-main>

    <MainMessageSnackbar :messages="messages" />
  </v-app>
</template>

<script>
import {mapGetters, mapState} from "vuex";

import AppBar from "@/components/AppBar";
import MainMessageSnackbar from "@/components/MainMessageSnackbar";

export default {
  name: "app",
  components: { AppBar, MainMessageSnackbar },
  computed: {
    ...mapState({
      user: state => state.user.user,
      messages: state => state.message.messages,
      loading: state => state.post.loading
    }),
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn"
    })
  }
};
</script>
