import axios from "axios";

export default {
  namespaced: true,
  state: {
    sorts: []
  },
  getters: {
    allSorts: state => state.sorts,
    oneSortById: state => id => {
      return state.sorts.find(sort => sort._id === id);
    }
  },
  actions: {
    async getAllSorts({ commit }) {
      let response = await axios.get("api/sort");

      commit("SET_SORTS",
        response.data.map(sort => ({
          ...sort,
          createdAt: new Date(sort.createdAt).toISOString(),
          heading: sort.name + ' (' + sort.color + ')'
        }))
      );
    },

    async addNewSort({ commit, dispatch, state }, newSort) {
      newSort.createdAt = Date.now();
      newSort.createdBy = newSort.user;
      let maxCode = state.sorts.reduce((max,p) => Math.max(max, p.code), state.sorts[0].code);
      newSort.code = maxCode + 1;

      let response = await axios.post("api/sort/", newSort);

      // check request success here - if it is needed to refresh vuex
      // move all interactions with database and response to snackbar already here

      commit("NEW_SORT", newSort);
      dispatch("sort/getAllSorts", null, { root: true });
      return response;
    },

    async updateOneSort({ dispatch }, updatedSort) {
      updatedSort.updatedAt = Date.now();
      updatedSort.updatedBy = updatedSort.user;

      let response = await axios.put(`api/vine/${updatedSort._id}`, updatedSort);

      dispatch("sort/getAllSorts", null, { root: true });
      return response;
    },

    async deleteSort({ commit }, id) {
      let response = await axios.delete(`api/sort/${id}`);

      commit("DELETE_SORT", id);
      return response;
    },

    emptyStore({ commit }) {
      commit("EMPTY_STORE");
    }
  },
  mutations: {
    SET_SORTS: (state, sorts) => (state.sorts = sorts),
    NEW_SORT: (state, newSort) => state.sorts.push(newSort),
    DELETE_SORT: (state, id) =>
      (state.sorts = state.sorts.filter(sort => sort._id !== id)),
    EMPTY_STORE: state => {
      state.sorts = [];
    }
  }
};
