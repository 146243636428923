<template>
  <v-container :class="['family-tree', (this.fullScreen ? 'full-screen-diagram' : '')]">

    <go-genogram :class="['geno-diagram', (this.fullScreen ? 'full-screen-diagram' : '')]"
                 style="left:0; top:0;" :reloadKey="reloadKey" @diagramClick="editSelectedEntry">
    </go-genogram>

    <v-divider class="my-5"/>
    <v-row>
      <v-col>
        <v-card flat>
          <person-form style="margin-top:16px;" :_model="updateModel" :_updating="this.updating" @reload="formSaveReload"></person-form>
        </v-card>
      </v-col>
      <v-col>
        <v-row class="ma-2 mt-0">
          <input id="personSearchBox" type="hidden" />
          <v-autocomplete ref="personSearch" label="Otsi"
              class="my-4" hide-no-data hide-details clearable solo dense flat outlined
              v-model="personSelect" :loading="loading" :items="items" :search-input.sync="personSearch" return-object
          ></v-autocomplete>
          <v-btn class="ma-4" icon color="green" large v-model="this.sorting" @click="reloadItemsArray"><v-icon>mdi-reload</v-icon></v-btn>
          <v-btn class="ma-4 ml-2" icon color="green" large v-model="this.sorting" @click="goFullscreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
        </v-row>
        <div v-for="person in sortedPersons">
          <db-entry type="person" :actions="true" :entry="person" :editable="true" @delete="deleteOnePerson(person._id)" @edit="editSelectedEntry"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import GoGenogram from "@/components/geno/GoGenogram";
import PersonForm from "@/components/forms/PersonForm";
import DbEntry from "@/components/DbEntry";

export default {
  name: "FamilyTree",
  props: { id: String },
  components: {
    GoGenogram, PersonForm, DbEntry
  },
  data: () => ({
    fullScreen: false,
    reloadKey: '',
    sorting: true,
    loading: false,
    personSearch: null,
    personSelect: null,
    items: [],
    updateModel: {},
    updating: false,
  }),
  mounted() {
    this.$store.dispatch("person/getAllPersons");
    this.items = this.allPersons;
    this.reloadDiagram();
  },
  computed: {
    ...mapGetters({
      allPersons: "person/allPersons",
      onePersonById: "person/onePersonById"
    }),
    sortedPersons() {
      switch (this.sorting) {
        case true:
          return this.items.sort(this.compareUpdatedAtDesc);
        case false:
          return this.items.sort(this.compareUpdatedAtAsc);
      }
      return this.items.sort(this.compareUpdatedAtDesc);
    },
  },
  methods: {
    ...mapActions({
      deleteOnePerson: "person/deletePerson",
      reloadPersonFromDb: "person/reloadPersonFromDb",
      setMessage: "message/setMessage"
    }),
    loadModel(id) {
      this.updateModel = this.onePersonById(id);
    },
    compareUpdatedAtDesc(a, b) {
      var a_comp = a.updatedAt || a.createdAt;
      var b_comp = b.updatedAt || b.createdAt;
      if (a_comp < b_comp) return 1;
      if (a_comp > b_comp) return -1;
      return 0;
    },
    compareUpdatedAtAsc(a, b) {
      var a_comp = a.updatedAt || a.createdAt;
      var b_comp = b.updatedAt || b.createdAt;
      if (a_comp < b_comp) return -1;
      if (a_comp > b_comp) return 1;
      return 0;
    },
    editSelectedEntry(id) {
      this.personSelect = this.onePersonById(id);
    },
    querySelections (v) {
      this.loading = true
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.allPersons.filter(e => {
          return (e.name || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
        })
        this.loading = false
      }, 100)
    },
    formSaveReload(_id) {
      this.reloadKey = _id;
      this.reloadItemsArray();
    },
    reloadItemsArray() {
      this.$store.dispatch("person/getAllPersons");
      this.items = [];
      this.personSelect = null;
      this.updateModel = {};
      this.loading = true;
      setTimeout(() => { this.items = this.allPersons; this.loading = false; },500);
      this.reloadDiagram();
    },
    reloadDiagram() {
      this.reloadKey = this.reloadKey + '*';
    },
    goFullscreen() {
      this.fullScreen = !this.fullScreen;
    }
  },
  watch: {
    id: function(newVal, oldVal) {
      if (newVal !== 'view') {
        this.loadModel(newVal);
      } else {
        this.updateModel = {};
      }
    },
    personSearch (val) {
      val && val !== this.personSelect && this.querySelections(val)
    },
    personSelect (obj) {
      if (obj) {
        this.$router.push({ name: 'tree', params: { id: obj._id } });
        this.$refs.personSearch.reset();
        this.items = [obj];
      }
    },
  }
};
</script>

<style>
.family-tree.full-screen-diagram {
  max-width: 100vw;
}
.geno-diagram.full-screen-diagram {
  width: 100vw;
  height: 87vh !important;
}
</style>