<template>
  <v-container class="database">
    <v-tabs grow color="green" show-arrows>

      <v-tab><v-icon left>mdi-sprout</v-icon>{{ $t("Vine") }}</v-tab>
      <v-tab><v-icon left>mdi-chart-bubble</v-icon>{{ $t("Sort") }}</v-tab>
      <v-tab><v-icon left>mdi-pound</v-icon>{{ $t("Tag") }}</v-tab>
      <v-tab><v-icon left>mdi-dots-hexagon</v-icon>{{ $t("Category") }}</v-tab>

      <v-tab-item>
        <v-row>
          <v-col cols="12" :lg="$vuetify.breakpoint.lgAndDown ? 12 : 6">
            <v-card flat>
              <vine-form :model="{}"></vine-form>
            </v-card>
          </v-col>
          <v-col cols="12" :lg="$vuetify.breakpoint.lgAndDown ? 12 : 6">
            <div v-for="vine in vines">
              <db-entry type="vine" :actions="true" :entry="vine" @delete="deleteOneVine(vine._id)" />
            </div>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <v-row>
          <v-col cols="12" :lg="$vuetify.breakpoint.lgAndDown ? 12 : 6">
            <v-card flat>
              <sort-form :model="{}"></sort-form>
            </v-card>
          </v-col>
          <v-col cols="12" :lg="$vuetify.breakpoint.lgAndDown ? 12 : 6">
            <div v-for="sort in sorts">
              <db-entry type="sort" :actions="true" :entry="sort" @delete="deleteOneSort(sort._id)" />
            </div>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <v-row>
          <v-col cols="12" :lg="$vuetify.breakpoint.lgAndDown ? 12 : 6">
            <v-card flat>
              <tag-form :model="{}"></tag-form>
            </v-card>
          </v-col>
          <v-col cols="12" :lg="$vuetify.breakpoint.lgAndDown ? 12 : 6">
            <div v-for="tag in tags">
              <db-entry type="tag" :actions="true" :entry="tag" @delete="deleteOneTag(tag._id)" />
            </div>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <v-row>
          <v-col cols="12" :lg="$vuetify.breakpoint.lgAndDown ? 12 : 6">
            <v-card flat>
              <category-form :model="{}"></category-form>
            </v-card>
          </v-col>
          <v-col cols="12" :lg="$vuetify.breakpoint.lgAndDown ? 12 : 6">
            <div v-for="category in categories">
              <db-entry type="category" :actions="true" :entry="category" @delete="deleteOneCategory(category._id)" />
            </div>
          </v-col>
        </v-row>
      </v-tab-item>

    </v-tabs>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import SortForm from "@/components/forms/SortForm";
import VineForm from "@/components/forms/VineForm";
import TagForm from "@/components/forms/TagForm";
import CategoryForm from "@/components/forms/CategoryForm";
import DbEntry from "@/components/DbEntry";

export default {
  name: "Database",
  components: {DbEntry, SortForm, VineForm, TagForm, CategoryForm},
  data: () => ({}),
  computed: {
    ...mapGetters({
      vines: "vine/allVines",
      sorts: "sort/allSorts",
      tags: "tag/allTags",
      categories: "category/allCategories",
    }),
  },
  created() {
    this.$store.dispatch("vine/getAllVines");
    this.$store.dispatch("sort/getAllSorts");
    this.$store.dispatch("tag/getAllTags");
    this.$store.dispatch("category/getAllCategories");
  },
  mounted() {
    this.$store.dispatch("vine/getAllVines");
    this.$store.dispatch("sort/getAllSorts");
    this.$store.dispatch("tag/getAllTags");
    this.$store.dispatch("category/getAllCategories");
  },
  methods: {
    ...mapActions({
      deleteOneVine: "vine/deleteVine",
      deleteOneSort: "sort/deleteSort",
      deleteOneTag: "tag/deleteTag",
      deleteOneCategory: "category/deleteCategory",
      setMessage: "message/setMessage"
    }),
    // async savePost() {
    //   this.validatePost();
    //   if (this.valid) {
    //     this.post.user = this.user;
    //
    //     // await this.addNewPost(this.post);
    //     let res;
    //
    //     if (this.updating) {
    //       res = await this.updateOnePost(this.post);
    //     } else {
    //       res = await this.addNewPost(this.post);
    //     }
    //
    //     if (res.status === 201 || res.status === 200) {
    //       this.setMessage({text: res.data.message, type: "success"});
    //     }
    //     this.updating = false;
    //   }
    //   this.resetPost();
    // },
    // validatePost() {
    //   this.valid = this.$refs.post.validate();
    // },
    // resetPost() {
    //   this.$refs.post.reset();
    //   this.$router.push({ name: 'blog', params: { id: 'new' } })
    // },
    // deleteEntryById(id) {
    //   this.deleteOneVine(id);
    // },
    // editSelectedEntry(id) {
      // this.post = this.onePostById(id)
      // this.updating = true
    // }
  }
};
</script>
