<template>
  <v-container class="cellar">
    <h1>{{ $t("Wine cellar") }}</h1>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Cellar",
  data: () => ({}),
  created() {},
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({})
  }
};
</script>
