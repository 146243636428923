<template>
  <v-list-item three-line>

    <v-badge style="align-self: center" right overlap dot class="mr-4">
      <v-avatar>
        <v-img class="elevation-6" alt="avatar" :src="avatarLink"></v-img>
      </v-avatar>
    </v-badge>

    <v-list-item-content>
      <div class="overline">{{ dateToLocaleEST(post.createdAt) }}
        <v-chip x-small>#{{post.code}}</v-chip>

        <v-btn v-if="actions" icon v-on:dblclick="deletePost()" color="red" class="pa-3 mx-1" x-small><v-icon>mdi-delete</v-icon></v-btn>

        <v-btn v-if="actions" icon v-on:click="editPost()" color="green" class="pa-3 mx-1" x-small><v-icon>mdi-pencil</v-icon></v-btn>

      </div>

      <v-list-item-title class="headline mb-1">
        {{ post.heading }}
      </v-list-item-title>

      <v-list-item-subtitle style="font-style: italic">{{ post.summary }}</v-list-item-subtitle>

<!--      <p v-for="(line, i) in psdost.text.split('\n')" :key="i">{{ line }}</p>-->
      <p>{{ post.text }}</p>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "PostEntry",
  props: {
    actions: Boolean,
    post: Object,
  },
  data: () => ({
    hover: false,
  }),
  mounted() {
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      avatars: "user/getAvatars",
      posts: "post/allPosts",
    }),
    avatarLink() {
      return this.avatars[this.post.createdBy];
    }
  },
  methods: {
    dateToLocaleEST(date) {
      return new Date(date).toLocaleDateString("ET-ee", {
        month: "long",
        day: "numeric",
        year: "numeric"
      });
    },
    deletePost() {
        this.$emit("delete");
    },
    editPost() {
      this.$emit("edit");
      this.$router.push({ name: 'blog', params: { id: this.post._id } })
    },
  },
};
</script>
