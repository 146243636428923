<template>
  <v-row justify="center">
    <v-col class="col-xs-12 col-md-6 text-xs-center display-1 mx-3">
      <v-card light>
        <v-card-title class="headline justify-center text-uppercase">
          {{ $t("app-name") }}
          <a href="https://github.com/jg-karl">
            <img class="mx-4 mt-2" src="../assets/jgk.png" alt="logo" id="logo" height="30px"/>
          </a>
        </v-card-title>
        <v-card-title class="subheading justify-center">
          {{ $t("app-description") }} <br> VueJS, Vuex, Vuetify, Express, Mongoose
        </v-card-title>
        <v-card-actions>
          <v-row class="justify-center">
            <v-btn class="ma-4" :to="{ name: 'register' }">
              {{ $t("Register") }}
              <v-icon class="ma-2">mdi-account-details</v-icon>
            </v-btn>
            <v-btn class="ma-4" :to="{ name: 'login' }">
              {{ $t("Login") }}
              <v-icon class="ma-2">mdi-account-arrow-right</v-icon>
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "About"
};
</script>