import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n"

import "leaflet/dist/leaflet.css"
import "leaflet-fullscreen/dist/leaflet.fullscreen.css"
import "leaflet-fullscreen/dist/Leaflet.fullscreen"
import "leaflet-draw/dist/leaflet.draw.css";
import "vue-swatches/dist/vue-swatches.css"

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
