import Vue from "vue";
import Router from "vue-router";
import store from "../store/index";

import Home from "@/pages/Home";
import About from "@/pages/About";
import Login from "@/pages/Login";
import Register from "@/pages/Register";

import Blog from "@/pages/Blog";
import News from "@/pages/News";
import Map from "@/pages/Map";
import Cellar from "@/pages/Cellar";
import Database from "@/pages/Database";
import Vineyard from "@/pages/Vineyard";
import FamilyTree from "@/pages/FamilyTree";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: "/about",
      name: "about",
      component: About
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/register",
      name: "register",
      component: Register
    },
    {
      path: "/news",
      name: "news",
      component: News,
      meta: { requiresAuth: true }
    },
    {
      path: "/blog/:id",
      name: "blog",
      component: Blog,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: "/map",
      name: "map",
      component: Map,
      meta: { requiresAuth: true }
    },
    {
      path: "/cellar",
      name: "cellar",
      component: Cellar,
      meta: { requiresAuth: true }
    },
    {
      path: "/tree/:id",
      name: "tree",
      component: FamilyTree,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: "/database",
      name: "database",
      component: Database,
      meta: { requiresAuth: true }
    },
    {
      path: "/vineyard",
      name: "vineyard",
      component: Vineyard,
      meta: { requiresAuth: true }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (store.getters["user/isLoggedIn"]) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
