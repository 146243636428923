<template>
  <v-card class="ma-3 mt-3" shaped raised :color="entry.sex === 'F' ? 'pink lighten-5': entry.sex === 'M' ? 'blue lighten-5' : ''">
    <v-list-item three-line>
      <div style="align-self: center" class="mr-4">
        <v-avatar>
          <v-icon>{{ this.icon }}</v-icon>
        </v-avatar>
      </div>

      <v-list-item-content>
        <div class="overline">{{ dateToLocaleEST(entry.createdAt) }}
          <v-chip x-small>#{{entry.code}}</v-chip>
          <v-btn v-if="actions" icon v-on:dblclick="deleteEntry()" color="red" class="pa-3 mx-1" x-small>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn v-if="actions && editable" icon v-on:click="editEntry()" color="green" class="pa-3 mx-1" x-small><v-icon>mdi-pencil</v-icon></v-btn>
        </div>

        <v-list-item-title v-if="type !== 'person'" class="headline mb-1">
          {{ entry.heading }}
        </v-list-item-title>
        <v-list-item-title v-if="type === 'person'" class="headline mb-1">
          {{ entry.text }}
          <div class="subtitle-1">
            {{ entry.mother ? 'ema: ' + entry.mother : '' }}{{ entry.father ? ' isa: ' + entry.father : '' }}
            {{ entry.wife ? 'naine: ' + entry.wife : '' }}{{ entry.husband ? ' mees: ' + entry.husband : '' }}
            {{ entry.attributes.length > 0 ? 'karakterid: ' + entry.attributes : '' }}{{ entry.desc ? ' kirjeldus: ' + entry.desc : '' }}
          </div>
        </v-list-item-title>

      </v-list-item-content>
      <v-card-actions class="my-n2">

        <v-btn x-large icon @click="expanded = !expanded">
          <v-icon>{{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-actions>

    </v-list-item>
    <v-expand-transition>
      <div v-show="expanded">
        <v-divider></v-divider>
        <v-card-text>
          <pre style="font-style: italic">{{ entry }}</pre>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "DbEntry",
  props: {
    type: String,
    actions: Boolean,
    entry: Object,
    editable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    hover: false,
    expanded: false
  }),
  mounted() {},
  computed: {
    ...mapGetters({
      user: "user/getUser",
      allPersons: "person/allPersons",
      onePersonById: "person/onePersonById",
    }),
    icon() {
      let icons = {
        'vine':'mdi-sprout',
        'sort':'mdi-chart-bubble',
        'tag':'mdi-pound',
        'category':'mdi-dots-hexagon',
      }
      return icons[this.type];
    }
  },
  methods: {
    dateToLocaleEST(date) {
      return new Date(date).toLocaleDateString("ET-ee", {
        month: "long",
        day: "numeric",
        year: "numeric"
      });
    },
    deleteEntry() {
      this.$emit("delete");
    },
    editEntry() {
      this.$emit("edit", this.entry._id);
    },
  },
};
</script>

<style scoped>

pre {
  font-size: 10px;
}

</style>
