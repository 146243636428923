<template>
  <v-container :class="'database-tag' ">
    <br>
    <v-form v-model="valid" ref="tag">

      <p>{{ $t("Enter tag") }}</p>

      <v-row>
        <v-select class="mx-1" cols="3" :items="types" dense outlined v-model="tag.type" :rules="rules" :label="$t('Type')" />
        <v-text-field class="mx-1" cols="3" dense outlined v-model="tag.text" :rules="rules" :label="$t('Text')"/>
      </v-row>

      <v-row>
        <v-text-field class="mx-1" cols="3" dense outlined v-model="tag.desc" :label="$t('Description')"/>
      </v-row>

      <form-buttons :valid="valid" @save="saveModel" @reset="resetModel"/>

    </v-form>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FormButtons from "@/components/FormButtons";

export default {
  name: "TagForm",
  components: { FormButtons },
  props: {
    model: Object
  },
  data: () => ({
    valid: false,
    types: ['Üldine','Muu'],
    rules: [v => !!v || 'Kohustuslik'], // TODO translate
    tag: {
      type: '',
      text: '',
      desc: '',
    }
  }),
  computed: {
    ...mapGetters({
      user: "user/getUser"
    }),
  },
  created() {
    this.$store.dispatch("tag/getAllTags");
  },
  methods: {
    ...mapActions({
      newTag: "tag/addNewTag",
      setMessage: "message/setMessage"
    }),
    async saveTag(model) {
      model = {
        ...model,
        user: this.user,
      };

      let res = await this.newTag(model);

      if (res.status === 201) {
        this.setMessage({
          text: `${this.$t(res.data.message)} (${res.data.data.type}: ${res.data.data.text})`,
          type: "success"
        });
      }
    },
    saveModel() {
      this.validateModel();
      if (this.valid) {
        this.saveTag(this.tag)
      }
      this.resetModel();
    },
    validateModel() {
      this.valid = this.$refs.tag.validate();
    },
    resetModel() {
      this.$refs.tag.reset();
    },
  }
}
</script>