<template>
  <div>
    <v-container class="homepage"/>
    <Dashboard class="justify-center"></Dashboard>
  </div>
</template>

<script>
import Dashboard from "@/components/Dashboard";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: { Dashboard },
  created() {
    this.$store.dispatch("post/getAllPosts");
    this.$store.dispatch("vine/getAllVines");
    this.$store.dispatch("sort/getAllSorts");
    this.$store.dispatch("tag/getAllTags");
    this.$store.dispatch("point/getAllPoints");
    this.$store.dispatch("category/getAllCategories");
  },
  mounted() {},
  computed: {
    ...mapGetters({})
  },
  methods: {}
};
</script>
