<template>
  <v-container justify="center">
    <v-row>
      <v-col cols="12" lg="6">
        <br>
        <v-form ref="post" v-model="valid">
          <v-row justify="center">
            <v-radio-group v-model="post.type" row :rules="genRules" >
              <v-radio v-for="(type,i) in blogTypes" :key="i" :label="type.label" :value="type.value"></v-radio>
            </v-radio-group>
          </v-row>

          <v-row>
            <v-text-field dense outlined :label="$t('Heading')" v-model="post.heading" :rules="genRules"/>
          </v-row>
          <v-row>
            <v-textarea dense rows="4" filled auto-grow :label="$t('Summary')" v-model="post.summary"/>
          </v-row>
          <v-row>
            <v-textarea dense rows="4" filled auto-grow :label="$t('Content')" v-model="post.text"/>
          </v-row>
          <v-autocomplete
              dense outlined small-chips deletable-chips
              multiple auto-select-first hide-selected hide-no-data
              v-model="post.categories"
              :label="$t('Category')"
              :items="generalCategories"
          >
          </v-autocomplete>
          <form-buttons :valid="valid" :updating="this.updating" @save="savePost" @reset="resetPost"/>
        </v-form>
      </v-col>

      <v-divider v-show="$vuetify.breakpoint.lgAndUp" class="ml-6 mr-3" vertical inset></v-divider>

      <v-col>
        <v-tabs grow color="green">

          <v-tab>
            <v-icon left>mdi-sprout</v-icon>
            {{ $t('Vineyard') }}
          </v-tab>
          <v-tab>
            <v-icon left>mdi-bee</v-icon>
            {{ $t('Beekeeper') }}
          </v-tab>

          <v-tab-item>
            <v-col>
              <v-row class="post-flex-grid">
                <v-card v-for="post in posts.filter(p => p.type === 'vineyard')" :key="post._id" shaped
                        raised class="ma-3 grid-column">
                  <post-entry :post="post" :actions="true" @delete="deleteOnePost(post._id)"
                              @edit="editSelectedPost(post._id)"/>
                </v-card>
              </v-row>
            </v-col>
          </v-tab-item>

          <v-tab-item>
            <v-col>
              <v-row class="post-flex-grid">
                <v-card v-for="post in posts.filter(p => p.type === 'beekeep')" :key="post._id" shaped raised
                        class="ma-3 grid-column">
                  <post-entry :post="post" :actions="true" @delete="deleteOnePost(post._id)"
                              @edit="editSelectedPost(post._id)"/>
                </v-card>
              </v-row>
            </v-col>
          </v-tab-item>

        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PostEntry from "@/components/PostEntry";
import FormButtons from "@/components/FormButtons";

export default {
  name: "Blog",
  components: {PostEntry, FormButtons},
  props: {id: String},
  data: () => ({
    updating: false,
    valid: false,
    blogTypes: [
        { label: 'Istandus', value: 'vineyard' },
        { label: 'Mesila', value: 'beekeep' }
    ],
    post: {
      type: 'vineyard',
      code: '',
      heading: '',
      text: '',
      summary: ''
    },
    genRules: [v => !!v || "Kohustuslik"]
  }),
  mounted() {
    if (this.id != null && this.id.length === 24) {
      this.post = this.onePostById(this.id)
      this.updating = true
    }
  },
  created() {
    this.$store.dispatch("post/getAllPosts");
    this.$store.dispatch("category/getAllCategories");
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      onePostById: "post/onePostById",
      posts: "post/allPosts",
      allCategories: "category/allCategories"
    }),
    generalCategories() {
      return this.allCategories.filter(c => c.type === 'Üldine')
    }
  },
  methods: {
    ...mapActions({
      addNewPost: "post/addNewPost",
      deleteOnePost: "post/deleteOnePost",
      updateOnePost: "post/updateOnePost",
      setMessage: "message/setMessage"
    }),
    async savePost() {
      this.validatePost();
      if (this.valid) {
        this.post.user = this.user;

        // await this.addNewPost(this.post);
        let res;

        if (this.updating) {
          res = await this.updateOnePost(this.post);
        } else {
          res = await this.addNewPost(this.post);
        }

        if (res.status === 201 || res.status === 200) {
          this.setMessage({text: res.data.message, type: "success"});
        }
        this.updating = false;
      }
      this.resetPost();
    },
    validatePost() {
      this.valid = this.$refs.post.validate();
    },
    resetPost() {
      this.$refs.post.reset();
      this.$router.push({ name: 'blog', params: { id: 'new' } })
    },
    deletePostById(id) {
      this.deleteOnePost(id);
    },
    editSelectedPost(id) {
      this.post = this.onePostById(id)
      this.updating = true
    }
  }
};
</script>

<style scoped>
.post-flex-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.grid-column {
  height: 100%;
  flex: 100%;
  max-width: 100%;
  padding: 0 4px;
}
</style>