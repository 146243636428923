import axios from "axios";

export default {
  namespaced: true,
  state: {
    posts: [],
    selectedPost: {},
    loading: false
  },
  getters: {
    isLoading: state => state.loading,
    allPosts: state => state.posts,
    latestPosts: state => state.posts.slice(-1)[0],
    onePostById: state => id => state.posts.find(post => post._id === id),
    allPostDates: state => state.posts.map(post => post.date),
    getUserEntries: state => currentUser =>
      state.posts.filter(post => post.user === currentUser)
  },
  actions: {
    async getAllPosts({ commit }) {
      let response = await axios.get("api/post");

      commit("SET_POSTS",
        response.data.map(post => ({
          ...post,
          createdAt: new Date(post.createdAt).toISOString()
        }))
      );
    },

    async addNewPost({ commit, dispatch, state }, newPost) {
      newPost.createdAt = Date.now();
      newPost.createdBy = newPost.user;
      let maxCode = state.posts.reduce((max,p) => Math.max(max, p.code), state.posts[0].code);
      newPost.code = maxCode + 1;

      let response = await axios.post("api/post", newPost);

      dispatch("post/getAllPosts", null, { root: true });
      return response;
    },

    async updateOnePost({ commit, dispatch }, updatedPost) {
      updatedPost.updatedAt = Date.now();
      updatedPost.updatedBy = updatedPost.user;

      let response = await axios.put(`api/post/${updatedPost._id}`, updatedPost);

      dispatch("post/getAllPosts", null, { root: true });
      return response;
    },

    async deleteOnePost({ commit }, id) {
      let response = await axios.delete(`api/post/${id}`);

      commit("DELETE_POST", id);
      return response;
    },

    // FIXME: loading feature disabled at the moment - fix
    setLoadingTrue({ commit }) { commit("SET_LOADING", true); },
    setLoadingFalse({ commit }) { commit("SET_LOADING", false); },

    emptyStore({ commit }) {
      commit("EMPTY_STORE");
    },
  },
  mutations: {
    SET_LOADING: (state, bool) => (state.loading = bool),
    SET_POSTS: (state, posts) => {
      state.posts = posts;
      state.loading = false;
    },
    DELETE_POST: (state, id) => {
      state.posts = state.posts.filter(post => post._id !== id);
      state.loading = false;
    },
    EMPTY_STORE: state => {
      state.posts = [];
      state.selectedPost = {};
    }
  }
};
