<template>
  <div id="myGenoGram"></div>
</template>

<script>
import * as genogram from "@/plugins/genogram";
import {mapGetters} from "vuex";

export default {
  name: "GoGenogram",
  props: {
    reloadKey: String
  },
  data: () => ({}),
  created() {
    this.initDiagram();
  },
  mounted() {
    this.initDiagram();
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      allPersons: "person/allPersons"
    }),
    allGenoPersons() {
      return this.allPersons.map(p => ({
          key: p.code,
          n: p.name,
          s: p.sex,
          m: p.mother ? p.mother : '',
          f: p.father ? p.father : '',
          ux: p.wife ? p.wife : '',
          vir: p.husband ? p.husband : '',
          a: p.attributes ? p.attributes : [],
          id: p._id,
          tooltip: p.text +
              (p.attributes.length >= 1 ? '\n---\n' + p.attributes.concat() : '') +
              (p.desc ? '\n---\n' + p.desc : ''),
        })
      )
    }
  },
  methods: {
    initDiagram() {
      genogram.init();
      genogram.setupDiagram(genogram.myDiagram, this.allGenoPersons, 12);
      this.addListeners();
    },
    addListeners() {
      genogram.myDiagram.addDiagramListener("ObjectSingleClicked",
        function(e) {
          var part = e.subject.part;
          if (!(part instanceof go.Link)) {
            const el = document.getElementById('personSearchBox');
            el.value = part.data.id;
            el.dispatchEvent(new Event('change'));
          }
        }
      );

      const el = document.getElementById('personSearchBox');
      el.addEventListener('change', () => this.$emit('diagramClick', el.value));
    },
  },
  watch: {
    reloadKey: function (newVal, oldVal) {
      if (newVal) {
        genogram.reloadModel(genogram.myDiagram, this.allGenoPersons, 1);
      }
    }
  }
}
</script>

<style scoped>
#myGenoGram {
  background-color: #F8F8F8;
  width:100%;
  height:800px;
  border: 1px solid black;
}
</style>