import axios from "axios";

let avatars = {
  'admin': 'https://avataaars.io/?accessoriesType=Blank&avatarStyle=Transparent&clotheType=ShirtCrewNeck&eyeType=Squint&eyebrowType=Default&facialHairColor=Black&facialHairType=Blank&hairColor=Black&hatColor=Black&mouthType=Default&topType=Eyepatch',
  'karl': 'https://avataaars.io/?accessoriesType=Wayfarers&avatarStyle=Transparent&clotheType=BlazerSweater&eyeType=Side&eyebrowType=Default&facialHairColor=Black&facialHairType=BeardMajestic&hairColor=BrownDark&hatColor=Blue03&mouthType=Smile&topType=ShortHairShortFlat',
  'nele': 'https://avataaars.io/?accessoriesType=Blank&avatarStyle=Transparent&clotheType=BlazerSweater&eyeType=Side&eyebrowType=Default&facialHairColor=Black&facialHairType=Blank&hairColor=Black&hatColor=Blue03&mouthType=Smile&topType=LongHairCurvy'
}

export default {
  namespaced: true,
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    user: localStorage.getItem("user") || "",
    user_email: ""
  },
  getters: {
    getUser: state => state.user,
    getUserEmail: state => state.user_email,
    getToken: state => state.token,
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    getAvatar: state => avatars[state.user],
    getAvatars: () => avatars,
  },
  actions: {
    async loginUser({ commit }, userInfo) {
      try {
        commit("AUTH_REQUEST");
        let response = await axios.post("/user/login", userInfo);
        let data = response.data;
        if (data.status === 200) {
          const user_email = data.email;
          const user_name = data.email.split("@")[0];
          const user_token = data.token;
          localStorage.setItem("token", user_token);
          localStorage.setItem("user", user_name);
          axios.defaults.headers.common["Authorization"] = user_token;
          commit("AUTH_SUCCESS", { user: user_name, user_email: user_email, token: user_token });
        } else {
          commit("AUTH_ERROR");
          localStorage.removeItem("user");
          localStorage.removeItem("token");
        }
        return data;
      } catch (err) {
        commit("AUTH_ERROR");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        return err;
      }
    },

    async registerUser({ commit }, registrationInfo) {
      try {
        commit("AUTH_REQUEST");
        let response = await axios.post("/user/register", registrationInfo);
        let data = response.data;

        if (data.status === 200) {
          commit("AUTH_REG");
        } else {
          commit("AUTH_ERROR");
          localStorage.removeItem("user");
          localStorage.removeItem("token");
        }

        return data;
      } catch (err) {
        commit("AUTH_ERROR", err);

        localStorage.removeItem("user");
        localStorage.removeItem("token");

        return err;
      }
    },

    logoutUser({ commit, dispatch }) {
      try {
        localStorage.removeItem("user");
        localStorage.removeItem("token");

        delete axios.defaults.headers.common["Authorization"];
        dispatch("post/emptyStore", null, { root: true });
        dispatch("vine/emptyStore", null, { root: true });
        dispatch("tag/emptyStore", null, { root: true });
        dispatch("point/emptyStore", null, { root: true });
        dispatch("category/emptyStore", null, { root: true });
        dispatch("message/emptyStore", null, { root: true });

        commit("LOGOUT_USER");
      } catch (err) {
        return err;
      }
    },

    async refreshToken({ commit }) {
      try {
        // commit("AUTH_REQUEST");
        // let response = await axios.post("/user/token_refresh");
        // let data = response.data;
        // TODO: DEV env hack because of hot reload
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
        // console.log(localStorage.getItem("token"));
        // commit("AUTH_SUCCESS", { user: this.state.user, token: token });
      } catch (err) {
        commit("AUTH_ERROR");

        localStorage.removeItem("user");
        localStorage.removeItem("token");

        return err;
      }
    }
  },
  mutations: {
    AUTH_REQUEST: state => (state.status = "loading"),
    AUTH_REG: state => (state.status = "success"),
    AUTH_ERROR: state => (state.status = "error"),
    AUTH_SUCCESS: (state, userDto) => {
      state.status = "success";
      state.token = userDto.token;
      state.user = userDto.user;
      state.user_email = userDto.user_email;
      state.avatar = avatars[userDto.user]
    },
    LOGOUT_USER: state => {
      state.status = "Log out";
      state.token = "";
      state.user = "";
      state.user_email = "";
      state.avatar = "";
    }
  }
};
