<template>
  <v-row justify="center">
    <v-btn cols="3" class="ma-1" :disabled="!valid" color="success" v-on:click="saveModel()">
      <v-icon class="mr-2">mdi-content-save</v-icon>
      {{ this.updating ? $t("Update") : $t("Save") }}
    </v-btn>
    <v-btn cols="3" class="ma-1" outlined color="error" v-on:click="resetModel()">
      <v-icon class="mr-2">mdi-backspace-outline</v-icon>
      {{ $t("Reset") }}
    </v-btn>
  </v-row>
</template>

<script>
export default {
  name: "FormButtons",
  props: {
    valid: Boolean,
    updating: Boolean
  },
  data: () => ({}),
  created() {
  },
  methods: {
    saveModel() {
      this.$emit("save");
    },
    resetModel() {
      this.$emit("reset");
    },
  }
}
</script>

<style scoped>

</style>