<template>
  <v-container :class="'database-sort' ">
    <br>
    <v-form v-model="valid" ref="sort">

      <p>{{ $t("Enter sort") }}</p>

      <v-row>
        <v-text-field class="mx-1" cols="3" dense outlined v-model="sort.name" :rules="rules" :label="$t('Name')"/>
        <v-text-field class="mx-1" cols="3" dense outlined :label="$t('Cluster')" v-model="sort.cluster"/>
        <v-text-field class="mx-1" cols="3" dense outlined :label="$t('Berry')" v-model="sort.berry"/>
        <v-text-field class="mx-1" cols="3" dense outlined :label="$t('Coldness')" v-model="sort.coldness"/>
      </v-row>

      <v-row>
        <v-text-field class="mx-1" cols="3" dense outlined v-model="sort.color" :rules="rules" :label="$t('Color')"/>
        <v-text-field class="mx-1" cols="3" dense outlined v-model="sort.origin" :label="$t('Origin')"/>
      </v-row>

      <form-buttons :valid="valid" @save="saveModel" @reset="resetModel"/>

    </v-form>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FormButtons from "@/components/FormButtons";

export default {
  name: "SortForm",
  components: { FormButtons },
  props: {
    model: Object
  },
  data: () => ({
    valid: false,
    rules: [v => !!v || 'Kohustuslik'], // TODO translate
    sort: {
      name: '',
      color: '',
      cluster: '',
      berry: '',
      coldness: '',
      origin: '',
    }
  }),
  computed: {
    ...mapGetters({
      user: "user/getUser"
    }),
  },
  created() {
    this.$store.dispatch("sort/getAllSorts");
  },
  methods: {
    ...mapActions({
      newSort: "sort/addNewSort",
      setMessage: "message/setMessage"
    }),
    async saveSort(model) {
      model = {
        ...model,
        user: this.user,
      };

      let res = await this.newSort(model);

      if (res.status === 201) {
        this.setMessage({
          text: `${this.$t(res.data.message)} (${res.data.data.name})`,
          type: "success"
        });
      }
    },
    saveModel() {
      this.validateModel();
      if (this.valid) {
        this.saveSort(this.sort)
      }
      this.resetModel();
    },
    validateModel() {
      this.valid = this.$refs.sort.validate();
    },
    resetModel() {
      this.$refs.sort.reset();
    },
  }
}
</script>