<template>
  <v-container class="news">
    <h1>{{ $t("All posts") }}</h1>
    <div>
      <v-row class="post-flex-grid">
        <v-card v-for="post in posts" :key="post._id" shaped raised
                class="ma-3 grid-column">
          <post-entry :post="post" :actions="false" @delete="deletePostById(post._id)"/>
        </v-card>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PostEntry from "@/components/PostEntry";

export default {
  name: "News",
  components: { PostEntry },
  data: () => ({}),
  created() {
    this.$store.dispatch("post/getAllPosts");
  },
  computed: {
    ...mapGetters({
      posts: "post/allPosts",
    })
  },
  methods: {
    ...mapActions({
      deleteOnePost: "post/deleteOnePost",
    }),
    deletePostById(id) {
      this.deleteOnePost(id);
    },
  }
};
</script>

<style scoped>
.post-flex-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}
.grid-column {
  flex: 47%;
  max-width: 47%;
  padding: 0 4px;
  margin-top: 8px;
  vertical-align: middle;
}
@media (max-width: 1270px) {
  .grid-column {
    flex: 47%;
    max-width: 47%;
  }
}
@media (max-width: 800px) {
  .grid-column {
    flex: 100%;
    max-width: 100%;
  }
}
</style>
