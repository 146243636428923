import axios from "axios";

export default {
  namespaced: true,
  state: {
    categories: []
  },
  getters: {
    allCategories: state => state.categories,
    oneCategoryById: state => id => {
      return state.categories.find(category => category._id === id);
    }
  },
  actions: {
    async getAllCategories({ commit }) {
      let response = await axios.get("api/category");

      commit("SET_CATEGORIES",
        response.data.map(category => ({
          ...category,
          createdAt: new Date(category.createdAt).toISOString(),
          heading: category.type + ': ' + category.text,
          value: category.text,
        }))
      );
    },

    async addNewCategory({ commit, state }, newCategory) {
      newCategory.createdAt = Date.now();
      newCategory.createdBy = newCategory.user;
      let maxCode = state.categories.reduce((max,p) => Math.max(max, p.code), state.categories[0].code);
      newCategory.code = maxCode + 1;

      let response = await axios.post("api/category", newCategory);

      commit("NEW_CATEGORY", newCategory);
      return response;
    },

    async updateOneCategory({ dispatch }, updatedCategory) {
      updatedCategory.updatedAt = Date.now();
      updatedCategory.updatedBy = updatedCategory.user;

      let response = await axios.put(`api/category/${updatedCategory._id}`, updatedCategory);

      dispatch("category/getAllCategories", null, { root: true });
      return response;
    },

    async deleteCategory({ commit }, id) {
      let response = await axios.delete(`api/category/${id}`);

      commit("DELETE_CATEGORY", id);
      return response;
    },

    emptyStore({ commit }) {
      commit("EMPTY_STORE");
    }
  },

  mutations: {
    SET_CATEGORIES: (state, categories) => (state.categories = categories),
    NEW_CATEGORY: (state, newCategory) => state.categories.push(newCategory),
    DELETE_CATEGORY: (state, id) =>
        (state.categories = state.categories.filter(category => category._id !== id)),
    EMPTY_STORE: state => {
      state.categories = [];
    }
  }
};
