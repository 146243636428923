import axios from "axios";

export default {
  namespaced: true,
  state: {
    tags: []
  },
  getters: {
    allTags: state => state.tags,
    oneTagById: state => id => {
      return state.tags.find(tag => tag._id === id);
    },
    isTag: state => query => {
      return state.tags.some(tag => tag.text === query);
    }
  },
  actions: {
    async getAllTags({ commit }) {
      let response = await axios.get("api/tag");

      commit("SET_TAGS",
        response.data.map(tag => ({
          ...tag,
          createdAt: new Date(tag.createdAt).toISOString(),
          heading: tag.type + ': ' + tag.text,
        }))
      );
    },

    async addNewTag({ commit, state }, newTag) {
      newTag.createdAt = Date.now();
      newTag.createdBy = newTag.user;
      let maxCode = state.tags.reduce((max,p) => Math.max(max, p.code), state.tags[0].code);
      newTag.code = maxCode + 1;

      let response = await axios.post("api/tag", newTag);

      commit("NEW_TAG", newTag);
      return response;
    },

    async updateOneTag({ dispatch }, updatedTag) {
      updatedTag.updatedAt = Date.now();
      updatedTag.updatedBy = updatedTag.user;

      await axios.put(`api/tag/${updatedTag._id}`, updatedTag);

      dispatch("tag/getAllTags", null, { root: true });
    },

    async deleteTag({ commit }, id) {
      let response = await axios.delete(`api/tag/${id}`);

      commit("DELETE_TAG", id);
      return response;
    },

    emptyStore({ commit }) {
      commit("EMPTY_STORE");
    }
  },
  mutations: {
    SET_TAGS: (state, tags) => (state.tags = tags),
    NEW_TAG: (state, newTag) => state.tags.push(newTag),
    DELETE_TAG: (state, id) =>
        (state.tags = state.tags.filter(tag => tag._id !== id)),
    EMPTY_STORE: state => {
      state.tags = [];
    }
  }
};
