import Vuex from "vuex";
import Vue from "vue";
import user from "./modules/user";
import post from "./modules/post";
import vine from "./modules/vine";
import sort from "./modules/sort";
import tag from "./modules/tag";
import point from "./modules/point";
import category from "./modules/category";
import person from "./modules/person";
import message from "./modules/message";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    post,
    vine,
    sort,
    tag,
    point,
    category,
    person,
    message
  }
});
