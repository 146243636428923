<template>
  <v-container :class="'database-person' ">
    <v-form v-model="valid" ref="person">

<!--      <div class="title mb-5">{{ this.updating ? $t("Update person") : $t("Enter person") }}</div>-->

      <v-row>
        <v-radio-group class="mx-1" style="margin-top:4px;" v-model="person.sex" row :rules="rules">
          <v-radio :label="$t('Male')" value="M"></v-radio>
          <v-radio :label="$t('Female')" value="F"></v-radio>
        </v-radio-group>
        <v-text-field class="mx-1" dense outlined v-model="person.name" :rules="rules" :label="$t('Name')"/>
      </v-row>
      <v-row>
        <v-text-field class="mx-1" dense outlined :label="$t('Birth')" v-model="person.birth" append-icon="mdi-calendar"/>
        <v-text-field class="mx-1" dense outlined :label="$t('Death')" v-model="person.death" append-icon="mdi-calendar"/>
      </v-row>
      <v-row>
        <v-autocomplete class="mx-1" cols="3" :search-input.sync="motherSearch" :items="allFemalePersons" dense outlined clearable v-model="person.mother" :label="$t('Mother')" />
        <v-autocomplete class="mx-1" cols="3" :search-input.sync="fatherSearch" :items="allMalePersons" dense outlined clearable v-model="person.father" :label="$t('Father')" />
      </v-row>
      <v-row>
        <v-autocomplete class="mx-1" cols="3" :search-input.sync="wifeSearch" :items="allFemalePersons" dense outlined clearable v-model="person.wife" :label="$t('Wife')" />
        <v-autocomplete class="mx-1" cols="3" :search-input.sync="husbandSearch" :items="allMalePersons" dense outlined clearable v-model="person.husband" :label="$t('Husband')" />
      </v-row>

      <v-row>
        <v-autocomplete class="mx-1" cols="3" dense outlined small-chips deletable-chips multiple auto-grow hide-no-data hide-selected
          :label="$t('Attributes')" v-model="person.attributes" :items="person.attributes" :search-input.sync="attrInput" @keyup.enter="addAttribute"
        />
      </v-row>
      <v-row>
        <v-text-field class="mx-1" cols="3" dense outlined v-model="person.desc" :label="$t('Description')"/>
      </v-row>

      <form-buttons :valid="valid" :updating="this.updating" @save="savePerson" @reset="resetPerson"/>

    </v-form>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FormButtons from "@/components/FormButtons";

export default {
  name: "PersonForm",
  components: { FormButtons },
  props: {
    _model: Object,
    _updating: Boolean
  },
  data: () => ({
    updating: false,
    birthMenu: false,
    deathMenu: false,
    motherSearch: '',
    fatherSearch: '',
    wifeSearch: '',
    husbandSearch: '',
    valid: false,
    rules: [v => !!v || 'Kohustuslik'], // TODO translate
    person: {
      sex: '',
      name: '',
      mother: 0,
      father: 0,
      wife: 0,
      husband: 0,
      attributes: [],
      desc: '',
    },
    attrInput: '',
  }),
  computed: {
    ...mapGetters({
      user: "user/getUser",
      allPersons: "person/allPersons",
      allMalePersons: "person/allMalePersons",
      allFemalePersons: "person/allFemalePersons",
    }),
    allPersonNames() {
      return this.allPersons.map(p => p.name)
    }
  },
  methods: {
    ...mapActions({
      addNewPerson: "person/addNewPerson",
      updateOnePerson: "person/updateOnePerson",
      setMessage: "message/setMessage"
    }),
    loadModel() {
      this.person = this._model;
      this.updating = true;
    },
    async savePerson() {
      this.validateModel();
      if (this.valid) {
        let model = {
          ...this.person,
          user: this.user
        };

        let res;
        if (this.updating) {
          res = await this.updateOnePerson(model);
        } else {
          res = await this.addNewPerson(model);
        }

        if (res.status === 201 || res.status === 200) {
          this.setMessage({
            text: `${this.$t(res.data.message)} (${res.data.data.code}: ${res.data.data.name})`,
            type: "success"
          });
          this.$emit('reload', this.person._id);
        }
      }
      this.resetPerson();
      this.$router.push({ name: 'tree', params: { person_id: 'view' } }, () => { this.updating = false });
    },
    validateModel() {
      this.valid = this.$refs.person.validate();
    },
    resetPerson() {
      this.$refs.person.reset();
      this.person = {};
      this.updating = false;
      this.$router.push({ name: 'tree', params: { id: 'view' } }, () => { this.updating = false; });
    },
    addAttribute() {
      this.$nextTick(() => {
        if (this.attrInput != null || this.attrInput !== '') {
          this.attrInput = this.attrInput.trim().toUpperCase();
          this.person.attributes.push(...this.attrInput.split(';'))
          this.$nextTick(() => {
            this.attrInput = null
          })
        }
      })
    },
  },
  watch: {
    _model: function(newVal, oldVal) {
      if (newVal.name) {
        this.loadModel();
      }
    },
    _updating: function(newVal, oldVal) {
      if (newVal) {
        this.updating = newVal;
      }
    }
  }
}
</script>